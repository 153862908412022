import { IMessage } from 'react-native-gifted-chat'

const post_headers = new Headers();
post_headers.append("accept", "application/json");
post_headers.append("Content-Type", "application/json");


export const requestChatbotId = async (hostname: string | undefined) => {
	try {
		const response = await fetch(`${process.env.REACT_APP_CHAT_SERVER_API}/bots/hostnames/${hostname}`, {
			headers: post_headers,
		});
		if (!response.ok) {
			console.error(`An error has occured: ${response.status}`)
		} else {
			const json = await response.json();
			console.debug(json)
			return json.id;
		}
	} catch (e) {
		console.error(e)
		return null
	}
}

export const requestSessionId = async (chatbotId: string | undefined) => {
	try {
		const response = await fetch(`${process.env.REACT_APP_CHAT_SERVER_API}/bots/${chatbotId}/sessions`, {
			method: 'POST',
			headers: post_headers,
			body: JSON.stringify({
				"chatbotId": chatbotId
			})
		});
		if (!response.ok) {
			console.error(`An error has occured: ${response.status}`)
		} else {
			const json = await response.json();
			console.debug(json)
			const sessionId = json.id
			const sponsorMessage = json.sponsor_message
			return { sessionId, sponsorMessage }
		}
	} catch (e) {
		console.error(e)
		return null
	}
}

export const getMessages = async (chatbotId: string, sessionId: string) => {
	let messages: any = []
	try {
		const response = await fetch(`${process.env.REACT_APP_CHAT_SERVER_API}/bots/${chatbotId}/sessions/${sessionId}/messages?size=1000`, {
			headers: post_headers,
		});
		if (!response.ok) {
			console.error(`An error has occured: ${response.status}`)
		} else {
			const data = await response.json();
			const message_data = data.messages
			const avatar = data.avatar
			for (let i = 0; message_data && i < message_data.length; i++) {
				const timeZoneOffset = new Date().getTimezoneOffset();
				const localDate = new Date(new Date(message_data[i].date_created).getTime() - timeZoneOffset * 60000);
				messages.push({
					_id: message_data[i].id,
					text: message_data[i].raw_text,
					user: (message_data[i].to_user_id === sessionId) ? {
						_id: 2,
						avatar: (avatar)? avatar:'/avatar.png',
					}:{_id: 1},
					createdAt: localDate
				})
			}
		}
	} catch (e) {
		console.error(e)
	} finally {
		return messages;
	}
}

export const sendMessage = async (chatbotId: string | undefined, sessionId: string, msg: IMessage) => {
	let replies: any = []
	let controller = new AbortController();
	const timeoutDuration = 27000;
	let timeout = setTimeout(() => {
		controller.abort()
		return replies;
	  }, timeoutDuration);
	try {
		const response = await fetch(`${process.env.REACT_APP_CHAT_SERVER_API}/message`, {
			signal: controller.signal,
			method: 'POST',
			headers: post_headers,
			body: JSON.stringify({
				"chatbot_id": chatbotId,
				"action": "message",
				"payload": {
				  "user_id": sessionId,
				  "file_ids": [],
				  "folder_ids": [],
				  "conversation_id": sessionId,
				  "raw_text": msg.text
				}
			  })
		});
		clearTimeout(timeout);
		if (!response.ok) {
			console.error(`An error has occured: ${response.status}`)
		} else {
			const json = await response.json();
			for (let i = 0; json.replies && i < json.replies.length; i++) {
				replies.push({
					_id: json.replies[i].id,
					text: json.replies[i].raw_text,
					user: {
						_id: 2,
						avatar: (json.avatar)? json.avatar:'/avatar.png',
					},
					createdAt: new Date()
				})
			}
		}
	} catch (e) {
		console.error(e)
	} finally {
		return replies;
	}
}