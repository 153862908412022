import React, { useState, useEffect } from 'react'
import { GiftedChat, IMessage, User, Send, Bubble, Composer } from 'react-native-gifted-chat'
import { View, StyleSheet, Image, Platform, ActivityIndicator, Text } from 'react-native'
import { sendMessage, getMessages, requestSessionId } from './ChatServer'
import './App.css'
import Cookies from 'universal-cookie';

const App: React.FC = () => {

  const cookieKeySessionIdPrefix = process.env.REACT_APP_SESSION_COOKIE_KEY || 'chatbot_session_'
  const cookieKeySessionSponsorMessagePrefix = process.env.REACT_APP_SESSION_SPONSOR_MESSAGE_COOKIE_KEY || 'chatbot_session_sponsor_message_'
  const chatServerHost = process.env.REACT_APP_CHAT_SERVER_HOST
  const rootElement = document.getElementById('root');
  const queryParams = new URLSearchParams(window.location.search);
  const fullscreen = (rootElement && rootElement.dataset && (rootElement.dataset.fullscreen === 'true' || queryParams.get('fullscreen') ==='true')) ? rootElement.dataset.fullscreen || queryParams.get('fullscreen'): false;
  const [loading, setLoading] = useState(false);
  let [messages, setMessages] = useState<IMessage[]>([])
  const [toggle, setToggle] = useState(!fullscreen);
  let [enableSponsorMessage, setEnableSponsorMessage] = useState("");
  
  const getChatbotId = async () => {
    let chatbotId = queryParams.get('chatbot_id');
    if (!chatbotId) {
      chatbotId = (rootElement && rootElement.dataset && rootElement.dataset.chatbotid) ? rootElement.dataset.chatbotid: "";
    }
    return chatbotId
  }

  const getSessionId = async () => {
    const chatbotId = await getChatbotId();
    const cookies = new Cookies();
    const cookieKey = cookieKeySessionIdPrefix + chatbotId 
    let sessionId = cookies.get(cookieKey);
    return sessionId
  }

  const getSessionSponsorMessage = async () => {
    const chatbotId = await getChatbotId();
    const cookies = new Cookies();
    const cookieKeyForSponsor = cookieKeySessionSponsorMessagePrefix + chatbotId 
    let sponsorMessage = cookies.get(cookieKeyForSponsor);
    return sponsorMessage
  }

  const createSessionId = async (chatbotId: string) => {
    const sessionResponse  = await requestSessionId(chatbotId)
    const sessionId = sessionResponse && sessionResponse.sessionId ? sessionResponse!.sessionId : null;
    const sponsorMessage = sessionResponse && sessionResponse.sponsorMessage ? sessionResponse!.sponsorMessage : "";
    if (sessionId) {
      const cookieKey = cookieKeySessionIdPrefix + chatbotId 
      const cookieKeyForSponsor = cookieKeySessionSponsorMessagePrefix + chatbotId 
      const cookies = new Cookies();
      cookies.set(cookieKey, sessionId, { path: '/' });
      cookies.set(cookieKeyForSponsor, sponsorMessage, { path: '/' });
      return  { sessionId, sponsorMessage }
    } else {
      return null;
    }
  }

  const onPageLoad = async () => {
    setLoading(true);
    const chatbotId = await getChatbotId();
    const sessionId = await getSessionId();
    if (sessionId) {
      const previous_messages = await getMessages(chatbotId, sessionId);
      setMessages(previous_messages)
      const _sponsorMessage = await getSessionSponsorMessage();
      setEnableSponsorMessage(_sponsorMessage)
    } else {
      const sessionResponse  = await createSessionId(chatbotId)
      const new_session_id = sessionResponse!.sessionId;
      const _sponsorMessage = sessionResponse!.sponsorMessage;
      const previous_messages = await getMessages(chatbotId, new_session_id);
      setEnableSponsorMessage(_sponsorMessage)
      setMessages(previous_messages)
    }
    setLoading(false);
  }
  
  useEffect(() => {
    onPageLoad()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onButtonClick = () => {
    setToggle(!toggle)
  };

  const wait = (duration: any) => {
    return new Promise((resolve) => {
      setTimeout(resolve, duration);
    });
  };

  const onSend = async (newMsg: IMessage[]) => {
    setLoading(true);
    const msgs = [...newMsg, ...messages]
    setMessages(msgs)
    const chatbotId = await getChatbotId();
    const sessionId = await getSessionId();
    const replies = await sendMessage(chatbotId, sessionId, newMsg[0])
    if (replies.length > 0) {
      setMessages([...replies, ...msgs])
    } else {
      let all_messages = []
      let retries = 0;
      while (all_messages.length <= msgs.length && retries < 20) {
        all_messages = await getMessages(chatbotId, sessionId);
        retries = retries + 1;
        await wait(15000);
      }
      setMessages(all_messages);
    }
    setLoading(false);
  }

  const user: User = { _id: 1, name: 'me' }
  const inverted = true


  const renderBubble = (props: any) => {
    return (
      <Bubble<IMessage>
        {...props}
        wrapperStyle={{ left: styles.leftBubbleWrapperBackgroundColor, right: styles.rightBubbleWrapperBackgroundColor }}
      />
    )
  }
  const renderComposer = (props: any) => {
    return (
      <Composer
        {...props}
        textInputProps={{
          multiline: false,
          autoFocus: true,
          blurOnSubmit: false,
          onKeyPress: (event: any) => {
            if (Platform.OS === "web" && event.shiftKey === false && event.key.toUpperCase() === "ENTER") {
              props.onSend([{ text: event.target.value }], true)
            }
          },
        }}
      />
    );
  }

  const renderSend = (props: any) => {
    return (
      <Send
        {...props}
        containerStyle={styles.sendContainer}
      >
        <Image
          style={styles.sendButtonIcon}
          source={{
            uri: chatServerHost + '/right-arrow.png',
          }}
        />
      </Send>
    );
  }

  return (
    <View style={styles.container}>
      {
        toggle ?
        <View>
          <View style={styles.buttonChat}>
            <img src={`${chatServerHost}/chat.png`} alt="Chat" onClick={onButtonClick} />
          </View>
        </View>
          :
          <View>
              <View style={fullscreen?fullscreen_styles.sponsor:styles.sponsor}>
              <Text style={styles.sponsorText}>{enableSponsorMessage}</Text>
            </View> 
 
          <View style={fullscreen?fullscreen_styles.buttonClose:styles.buttonClose }>
            <img src={`${chatServerHost}/cross.png`} alt="Close" onClick={onButtonClick} />
          </View>
          <View style={fullscreen?fullscreen_styles.chat:styles.chat}>
            <GiftedChat {...{
              messages,
              onSend,
              alwaysShowSend: true,
              user,
              inverted,
              textInputStyle: styles.textinput,
              renderBubble: renderBubble,
              renderComposer: renderComposer,
              renderSend: renderSend,
            }} />
              <View>
                {
                loading?
                  <ActivityIndicator size="small" color='#000000' style={styles.loadingIndicator}/>
                  :
                  <View></View>
                }
              </View>
          </View>
        </View>
      }
    </View>
  )
}

const fullscreen_styles = StyleSheet.create({
  chat: {
    height: '100vh',
    width: '100vw',
    backgroundColor: '#fff',
    alignSelf: 'flex-end',
    marginBottom: 10,
    justifyContent: 'flex-end',
    outline: 'none !important',
  },
  sponsor: {
    width: '100vw',
    position: 'absolute',
    alignSelf: 'flex-end',
    backgroundColor: 'transparent',
    marginBottom: -20,
    paddingTop:50,
    marginRight: 10,
    height:20,
    elevation: 3,
    zIndex: 3
  },
  buttonClose: {
    width: 30,
    alignSelf: 'flex-end',
    marginBottom: 5,
    marginRight: 10,
    opacity:0
  },
});
const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-end',
    height: '100vh',
  },
  loadingIndicator: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{ translateX: -20 }, { translateY: -30 }],
  },
  chat: {
    height: 'calc(100vh - 100px)',
    width: 'calc(100vw - 20px)',
    maxWidth: 500,
    backgroundColor: '#fff',
    alignSelf: 'flex-end',
    borderRadius: 10,
    paddingBottom: 10,
    marginRight: 10,
    marginBottom: 10,
    justifyContent: 'flex-end',
    outline: 'none !important',
    boxShadow: '1px 2px 9px 3px #aaa',
  },
  textinput: {
    paddingTop: 10,
    fontSize: 14,
    lineHeight: 18,
    outline: 'none !important'
  },
  sendButtonIcon: {
    width: 24,
    height: 24,
    opacity: 0.6,
    marginTop: 10,
    outline: 'none !important'
  },
  sendContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginRight: 15,
    outline: 'none'
  },
  leftBubbleWrapperBackgroundColor: {
  },
  rightBubbleWrapperBackgroundColor: {
    backgroundColor: '#666',
  },
  buttonChat: {
    width: 60,
    alignSelf: 'flex-end',
    margin: 10
  },
  buttonClose: {
    width: 30,
    alignSelf: 'flex-end',
    marginBottom: 5,
    marginRight: 10,
    opacity:1
  },
  sponsor: {
    width: 'calc(100vw - 180px)',
    maxWidth: 500,
    backgroundColor: 'transparent',
    position: 'absolute',
    alignSelf: 'flex-end',
    marginBottom: 10,
    paddingTop:10,
    marginRight: 10,
    height:20
  },
  sponsorText: {
    color: '#aaa',
    fontSize: 12,
    textAlign: 'center',
  }
})

export default App